<template>
  <div>
    <div class="grid gap-x-3 border-b" style="grid-template-columns: auto 1fr">
      <label for="name" class="p-4 bg-gray-100">Title</label>
      <input type="text" id="name" class="w-full outline-none" v-on:blur="saveName" placeholder="Type to name your list" v-model="listInfo.name"/>
    </div>
    <swipe-list ref="list" class="p-2" :items="listItems" item-key="id" 
    :threshold="90"
    @leftRevealed="setLastEvent('add', $event)"
    @rightRevealed="setLastEvent('remove', $event)"
    >
      <template v-slot="{ item }">
        <div class="border-b border-pink-100 py-4 px-3 font-light">
          <span>{{item.quantity | quantityFormat }}</span> {{ item.title }}
        </div>
      </template>
      <template v-slot:left="{ }">
        <div class="p-4 bg-blue-700 text-white" title="Add Item">
          <div class="w-20 py-1.5 uppercase text-sm text-center">+ 1</div>
        </div>
        <div class="swipeout-action">
          <div style="width:80px"></div>
        </div>
      </template>
      <template v-slot:right="{ }">
        <div class="swipeout-action">
          <div style="width:30px"></div>
        </div>
        <div class="p-4 bg-red-700 text-white">
          <div class="uppercase text-xs w-28 py-1.5">Remove</div>
        </div>
      </template>
      <template v-slot:empty>
        <div class="no-results">No items found. You can add this to the catalog by pressing + button</div>
      </template>
    </swipe-list>
    <div class="text-center mt-8">
      <button v-if="listItems.length === 0 && !listInfo.id" class="btn-blue active:translate-y-1" @click="onCreate">Create new list</button>
      <button v-if="listItems.length === 0 && listInfo.id" class="btn-blue active:translate-y-1" @click="onAdd">Add to your list</button>
      <div class="grid grid-cols-2 gap-x-4">
        <button v-if="listItems.length" class="btn-red w-44 justify-self-end active:translate-y-1" @click="onReset">Reset list</button>
        <button v-if="listItems.length" class="btn-blue w-44 active:translate-y-1" @click="onShare">Share your list</button>
      </div>
    </div>
  </div>
</template>


<script>
import { SwipeList, SwipeOut } from 'vue-swipe-actions'
import { mapState } from 'vuex'

export default {
  name: 'Stage',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SwipeOut,
    SwipeList
  },
  computed: {
    ...mapState(['listItems', 'listInfo']),
  },
  filters: {
    quantityFormat: function (value) {
      if (!value || value == 1) return ''
      return value + ' x '
    }
  },
  methods: {
    setLastEvent(name, { item }) {
      if (name === 'add') {
        this.addToList(item)
      }
      if (name === 'remove') {
        this.removeFromList(item)
      }
      this.closeAll()
    },
    onCreate() {
      this.$store.dispatch('resetList')
    },
    onAdd () {
      this.$router.push('/')
    },
    onReset () {
      this.$store.dispatch('resetList')
    },
    onShare () {
      this.$router.push('/list/share')
    },
    saveName () {
      this.$store.dispatch('updateListName', this.listInfo.name)
    },
    addToList(item) {
      this.$store.dispatch('addToList', item)
    },
    removeFromList(item) {
      this.$store.dispatch('removeFromList', item)
    },
    closeAll() {
      this.$refs.list.closeActions()
    }
  },
  mounted() {
    this.$store.dispatch('fetchList')
  }
}
</script>
